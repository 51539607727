import React, { createContext } from 'react'

const defaultState = {
	filters: {},
	spfilters: {},
	theme: 0
}

const PasifikiContext = createContext(defaultState)

const PasifikiProvider = ({ children }) => {
	const filters = {}
	const spfilters = {}
	const theme = 0

	return (
		<PasifikiContext.Provider
			value={{
				filters,
				spfilters,
				theme
			}}
		>
			{children}
		</PasifikiContext.Provider>
	)
}

export default PasifikiContext

export { PasifikiProvider }
