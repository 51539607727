exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-career-list-jsx": () => import("./../../../src/pages/career/list.jsx" /* webpackChunkName: "component---src-pages-career-list-jsx" */),
  "component---src-pages-career-quiz-collect-data-jsx": () => import("./../../../src/pages/career/quiz-collect-data.jsx" /* webpackChunkName: "component---src-pages-career-quiz-collect-data-jsx" */),
  "component---src-pages-career-quiz-jsx": () => import("./../../../src/pages/career/quiz.jsx" /* webpackChunkName: "component---src-pages-career-quiz-jsx" */),
  "component---src-pages-career-skill-jsx": () => import("./../../../src/pages/career/skill.jsx" /* webpackChunkName: "component---src-pages-career-skill-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-forbidden-jsx": () => import("./../../../src/pages/forbidden.jsx" /* webpackChunkName: "component---src-pages-forbidden-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-job-employer-jsx": () => import("./../../../src/pages/job/employer.jsx" /* webpackChunkName: "component---src-pages-job-employer-jsx" */),
  "component---src-pages-job-list-jsx": () => import("./../../../src/pages/job/list.jsx" /* webpackChunkName: "component---src-pages-job-list-jsx" */),
  "component---src-pages-jobtype-institutions-jsx": () => import("./../../../src/pages/jobtype/institutions.jsx" /* webpackChunkName: "component---src-pages-jobtype-institutions-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-serviceprovider-list-jsx": () => import("./../../../src/pages/serviceprovider/list.jsx" /* webpackChunkName: "component---src-pages-serviceprovider-list-jsx" */),
  "component---src-pages-tender-list-jsx": () => import("./../../../src/pages/tender/list.jsx" /* webpackChunkName: "component---src-pages-tender-list-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-training-list-jsx": () => import("./../../../src/pages/training/list.jsx" /* webpackChunkName: "component---src-pages-training-list-jsx" */),
  "component---src-templates-career-detail-jsx": () => import("./../../../src/templates/career/detail.jsx" /* webpackChunkName: "component---src-templates-career-detail-jsx" */),
  "component---src-templates-job-detail-jsx": () => import("./../../../src/templates/job/detail.jsx" /* webpackChunkName: "component---src-templates-job-detail-jsx" */),
  "component---src-templates-jobtype-detail-jsx": () => import("./../../../src/templates/jobtype/detail.jsx" /* webpackChunkName: "component---src-templates-jobtype-detail-jsx" */),
  "component---src-templates-serviceprovider-detail-jsx": () => import("./../../../src/templates/serviceprovider/detail.jsx" /* webpackChunkName: "component---src-templates-serviceprovider-detail-jsx" */)
}

