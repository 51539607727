import fetch from 'isomorphic-fetch'
import React from 'react'
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { PasifikiProvider } from './src/resources/context'

const client = new ApolloClient({
	link: new HttpLink({
		uri: process.env.GATSBY_API_GRAPHQL_URL,
		fetch
	}),
	cache: new InMemoryCache()
})

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={client}>
		<PasifikiProvider>{element}</PasifikiProvider>
	</ApolloProvider>
)
